
/*===========================
Start Hero Area CSS
=============================*/
.slider .single-slider {
    height: 600px;
    background-size: cover;
    background-position: center;
    background-repeat:no-repeat;
    }
    .slider .single-slider .text{
    margin-top:120px;
    }
    .slider.index2 .single-slider .text{
    margin-top:150px;
    
    }
    .slider .single-slider h3 {
      color: #1a76d1;
      font-size: px;
      font-weight: bold;
      margin: 0;
      padding: 0;
      line-height: 42px;
      }
    .slider .single-slider h1 span{
    color:#1a76d1;
    }
    .slider .single-slider p {
    color: #2C2D3F;
    margin-top: 27px;
    font-weight: 400;
    }
    .slider .single-slider .button{
    margin-top:30px;
    }
    .slider .single-slider .btn{
    color:#fff;
    background:#1a76d1;
    font-weight:500;
    display:inline-block;
    margin:0;
    margin-right:10px;
    }
    .slider .single-slider .btn:last-child{
    margin:0;
    }
    .slider .single-slider .btn.primary{
    background:#2C2D3F;
    color:#fff;
    }
    .slider .single-slider .btn.primary:before{
    background:#1A76D1;
    }
    .slider .owl-carousel .owl-nav {
    margin: 0;
      position: absolute;
      top: 50%;
      width: 100%;
    margin-top:-25px;
    }
    .slider .owl-carousel .owl-nav div {
    height: 50px;
    width: 50px;
    line-height: 50px;
    text-align: center;
    background: #1A76D1;
    color: #fff;
    font-size: 26px;
    position: absolute;
    margin: 0;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    padding: 0;
    border-radius: 50%;
    }
    .slider .owl-carousel .owl-nav div:hover{
    background:#2C2D3F;
    color:#fff;
    }
    .slider .owl-carousel .owl-controls .owl-nav .owl-prev{
    left:20px;
    }
    .slider .owl-carousel .owl-controls .owl-nav .owl-next{
    right:20px;
    }
    
    /* Slider Animation */
    .owl-item.active .single-slider h1{
      animation: fadeInUp 1s both 0.6s;
    }
    .owl-item.active .single-slider p{
      animation: fadeInUp 1s both 1s;
    }
    .owl-item.active .single-slider .button{
      animation: fadeInDown 1s both 1.5s;
    }
    /*===========================
    End Hero Area CSS
    =============================*/
    .leadership-section {
      display: flex;
      flex-direction: row; /* Keep it horizontal by default */
      align-items: center; /* Center vertically */
      margin: 40px 20px; /* Add top and bottom spacing, and left/right spacing */
      padding: 20px; /* Optional: add padding inside the section */
      
  }
  
  .row {
      display: flex;
      flex-direction: row; /* Default to row layout */
      width: 100%;
  }
  
  .col {
      flex: 1; /* Each column takes equal space */
      padding: 10px; /* Add some padding */
  }
  
  .leadership-image {
      max-width: 100%; /* Responsive image */
      height: auto; /* Maintain aspect ratio */
  }
  
  /* Mobile responsiveness */
  @media (max-width: 768px) {
      .leadership-section {
          flex-direction: column; /* Stack columns vertically */
      }
  
      .row {
          flex-direction: column; /* Ensure row items stack */
      }
  
      .col {
          width: 100%; /* Each column takes full width */
          padding: 10px 0; /* Add some vertical padding */
      }
  }
  .contact-button {
    background-color: #1a76d1; /* Button color */
    color: white; /* Text color */
    padding: 10px 20px; /* Padding */
    border: none; /* Remove border */
    border-radius: 5px; /* Rounded corners */
    font-size: 16px; /* Font size */
    cursor: pointer; /* Pointer cursor */
    margin-top: 20px; /* Space above the button */
    transition: background-color 0.3s; /* Transition effect */
}

.contact-button:hover {
    background-color: #2C2D3F; /* Darker shade on hover */
}
