
/*=============================
Start Feautes CSS
===============================*/
.Feautes{
    padding-top:0;
    }
    .Feautes.index2{
    padding-top:100px;
    }
    .Feautes.testimonial-page{
    padding-top:100px;
    }
    .Feautes .single-features{
    text-align:center;
    position:relative;
    padding:10px 20px;
    }
    .Feautes .single-features::before {
    position: absolute;
    content: "";
    right: -72px;
    top: 60px;
    width: 118px;
    border-bottom: 3px dotted #1a76d1;
    }
    .Feautes .single-features.last::before{
    display:none;
    }
    .Feautes .single-features .signle-icon{
    position:relative;
    }
    .Feautes .single-features .signle-icon i{
    font-size:50px;
    color:#1a76d1;
    position:absolute;
    left:50%;
    margin-left:-50px;
    top:0;
    height:100px;
    width:100px;
    line-height:100px;
    text-align:center;
    border:1px solid #dddddd;
    border-radius:100%;
    -webkit-transition:all 0.4s ease;
    -moz-transition:all 0.4s ease;
    transition:all 0.4s ease;
    }
    .Feautes .single-features:hover .signle-icon i{
    background:#1A76D1;
    color:#fff;
    border-color:transparent;
    }
    .Feautes .single-features h3{
    padding-top: 128px;
    color:#2C2D3F;
    font-weight:600;
    font-size:21px;
    }
    .Feautes .single-features p {
    margin-top: 20px;
    }
    /*=============================
    End Feautes CSS
    ===============================*/