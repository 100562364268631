
/*===========================
Start Hero Area CSS
=============================*/
.slider .single-slider {
    height: 600px;
    background-size: cover;
    background-position: center;
    background-repeat:no-repeat;
    }
    .slider .single-slider .text{
    margin-top:120px;
    }
    .slider.index2 .single-slider .text{
    margin-top:150px;
    }
    .slider .single-slider h3 {
      color: #1a76d1;
      
      font-weight: bold;
      margin: 0;
      padding: 0;
      line-height: 42px;
      }
    .slider .single-slider h1 span{
    color:#1a76d1;
    }
    .slider .single-slider p {
    color: #2C2D3F;
    margin-top: 27px;
    font-weight: 400;
    }
    .slider .single-slider .button{
    margin-top:30px;
    }
    .slider .single-slider .btn{
    color:#fff;
    background:#1a76d1;
    font-weight:500;
    display:inline-block;
    margin:0;
    margin-right:10px;
    }
    .slider .single-slider .btn:last-child{
    margin:0;
    }
    .slider .single-slider .btn.primary{
    background:#2C2D3F;
    color:#fff;
    }
    .slider .single-slider .btn.primary:before{
    background:#1A76D1;
    }
    .slider .owl-carousel .owl-nav {
    margin: 0;
      position: absolute;
      top: 50%;
      width: 100%;
    margin-top:-25px;
    }
    .slider .owl-carousel .owl-nav div {
    height: 50px;
    width: 50px;
    line-height: 50px;
    text-align: center;
    background: #1A76D1;
    color: #fff;
    font-size: 26px;
    position: absolute;
    margin: 0;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    padding: 0;
    border-radius: 50%;
    }
    .slider .owl-carousel .owl-nav div:hover{
    background:#2C2D3F;
    color:#fff;
    }
    .slider .owl-carousel .owl-controls .owl-nav .owl-prev{
    left:20px;
    }
    .slider .owl-carousel .owl-controls .owl-nav .owl-next{
    right:20px;
    }
    
    /* Slider Animation */
    .owl-item.active .single-slider h1{
      animation: fadeInUp 1s both 0.6s;
    }
    .owl-item.active .single-slider p{
      animation: fadeInUp 1s both 1s;
    }
    .owl-item.active .single-slider .button{
      animation: fadeInDown 1s both 1.5s;
    }
    /*===========================
    End Hero Area CSS
    =============================*/
    /*=========================
   Start Contact Us CSS
===========================*/
.contact-us .inner{
	box-shadow: 0px 0px 10px #00000024;
	border-radius: 5px;
	overflow:hidden;
}
.contact-us .contact-us-left{
    width: 100%;
    height: 100%;
}
.contact-us .contact-us-form{
	padding:50px 40px;
}
.contact-us .contact-us-form h2 {
	position: relative;
	font-size: 32px;
	color: #333;
	font-weight: 600;
	line-height: 27px;
	text-transform: capitalize;
	margin-bottom: 12px;
	padding-bottom: 20px;
	text-align: left;
}
.contact-us .contact-us-form h2:before{
	position:absolute;
	content:"";
	left:0;
	bottom:0;
	height:2px;
	width:50px;
	background:#1A76D1;
}
.contact-us .contact-us-form {
	font-size: 14px;
	color: #333;
	font-weight: 400;
	text-align: left;
	margin-bottom:50px;
}
.contact-us .form {
	margin-top: 30px;
}
.contact-us .form .form-group {
	margin-bottom: 22px;
}
.contact-us .form .form-group input {
	width: 100%;
	height: 50px;
	border: 1px solid #eee;
	text-transform: capitalize;
	padding: 0px 18px;
	color: #555;
	font-size: 14px;
	font-weight: 400;
	border-radius: 4px;
}
.contact-us .form .form-group textarea {
	width: 100%;
	height:100px;
	border: 1px solid #eee;
	text-transform: capitalize;
	padding:18px;
	color: #555;
	font-size: 14px;
	font-weight: 400;
	border-radius: 4px;
}
.contact-us .form .form-group.login-btn {
	margin: 0;
}
.contact-us .form button {
	border: none;
}
.contact-us .form .btn {
	display: inline-block;
	
	color: #fff;
	line-height: 20px;
	
	text-align: center;
}
.contact-us .form .btn:hover{
	background:#1A76D1;
	color:#fff;
}

.contact-us .contact-info{
	margin-top:50px;
}
.contact-us .single-info {
	background: #1a76d1;
	padding: 40px 60px;
	height: 150px;
	border-radius: 10px;
	position:relative;
	-webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s;
}
.contact-us .single-info:before{
	position: absolute;
    z-index: -1;
    content: '';
    bottom: -10px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 80%;
    height: 90%;
    background:#1A76D1;
    opacity: 0;
    filter: blur(10px);
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s;
}
.contact-us .single-info:hover:before{
	opacity: 0.8;
}
.contact-us .single-info:hover{
	transform: translateY(-5px);
}
.contact-us .single-info i{
	font-size:42px;
	color:#fff;
	position:absolute;
	left:40px;
}
.contact-us .single-info .content {
	margin-left: 45px;
}
.contact-us .single-info .content h3{
	color:#fff;
	font-size:18px;
	font-weight:600;
	
}
.contact-us .single-info .content p{
	color:#fff;
	margin-top:5px;
}


/* Spinner */
.spinner {
	border: 4px solid rgba(0, 0, 0, 0.1);
	border-left: 4px solid #3498db;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	animation: spin 1s linear infinite;
	margin-top: 20px;
  }
  
  @keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
  }
  
  /* Success & Error messages */
  .success-message p {
	color: green !important;
	font-weight: bold !important;
	margin-top: 15px;
  }

  
  .error-message p {
	color: red !important;
	font-weight: bold !important;
	margin-top: 15px;
  }
  
  .success-message {
    color: green;
    font-weight: bold;
}

.error-message {
    color: red;
    font-weight: bold;
}
.contact_section{
padding:100px 0;
} 
/*=========================
   End Contact Us CSS
===========================*/