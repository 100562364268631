
/*===================
Start Header CSS 
=====================*/
.header{
    background-color:#fff;
    position:relative;
    }
    .header .navbar-collapse{
    padding:0;
    }
    /* Topbar */
    .header .topbar{
    background-color:#fff;
    border-bottom:1px solid #eee;
    }
    .header .topbar{
    padding:15px 0;
    }
    .header .top-link{
    float:left;
    }
    .header .top-link li{
    display:inline-block;
    margin-right:15px;
    }
    .header .top-link li:last-child{
    margin-right:0px;
    }
    .header .top-link li a{
    color:#2C2D3F;
    font-size:14px;
    font-weight:400;
    }
    .header .top-link li:hover a{
    color:#1A76D1;
    }
    .header .top-contact{
    float:right;
    }
    .header .top-contact li{
    display:inline-block;
    margin-right:25px;
    color:#2C2D3F;
    }
    .header .top-contact li:last-child{
    margin-right:0;
    }
    .header .top-contact li a{
    font-size:14px;
    }
    .header .top-contact li a:hover{
    color:#1A76D1;
    }
    .header .top-contact li i{
    color:#1A76D1;
    margin-right:8px;
    }
    .header .header-inner {
    background:#fff;
    z-index:999;
    width:100%;
    }
    .get-quote{
    margin-top:12px;
    }
    .get-quote .btn{
    color:#fff;
    }
    .header .logo {
    float: left;
    margin-top: 18px;
    }
    .header .navbar {
    background: none;
    box-shadow: none;
    border: none;
    margin: 0;
    height: 0px;
    min-height: 0px;
    }
    .header .nav li{
    margin-right: 15px;
      float: left;
    position:relative;
    }
    .header .nav li:last-child{
    margin:0;
    }
    .header .nav li a {
    color: #2C2D3F;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    padding: 25px 12px;
    position: relative;
    display: inline-block;
    position:relative;
    }
    .header .nav li a::before {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    height: 3px;
    width:0%;
    background: #1A76D1;
    border-radius: 5px 5px 0 0;
    opacity:0;
    visibility:hidden;
    -webkit-transition:all 0.4s ease;
    -moz-transition:all 0.4s ease;
    transition:all 0.4s ease;
    }
    .header .nav li.active a:before{
    opacity:1;
    visibility:visible;
    width: 100%;
    }
    .header .nav li.active a{
    color:#1A76D1;
    }
    .header .nav li:hover a:before{
    opacity:1;
    width: 100%;
    visibility:visible;
    }
    .header .nav li:hover a{
    color:#1a76d1;
    }
    .header .nav li a i {
    display: inline-block;
    margin-left: 1px;
    font-size: 13px;
    }
    /* Middle Header */
    .header.style2 .header-inner {
    border-top: 1px solid #eee;
    }
    .header.style2 .logo {
    margin-top:6px;
    }
    .header .middle-header {
    background: #fff;
    padding: 20px 0px;
    }
    .header .widget-main{
    float:right;
    }
    .header.style2 .get-quote {
    margin-top: 0;
    }
    .header .single-widget {
    position: relative;
    float: left;
    margin-right: 30px;
    padding-left: 55px;
    }
    .header .single-widget:last-child{
    margin:0;
    }
    .header .single-widget .logo{
    margin:0;
    padding:0;
    margin-top: 7px;
    }
    .header .single-widget i {
    position: absolute;
    left: 0;
    top: 6px;
    height: 40px;
    width: 40px;
    line-height: 40px;
    color: #fff;
    background: #1A76D1;
    border-radius: 4px;
    text-align: center;
    font-size: 15px;
    }
    .header .single-widget h4 {
    font-size: 15px;
    font-weight: 500;
    }
    .header .single-widget p {
    margin-bottom: 5px;
    text-transform: capitalize;
    }
    .header .single-widget.btn{
    margin-left:0;
    }
    /* Dropdown Menu */
    .header .nav li .dropdown {
    background: #fff;
    width: 220px;
    position: absolute;
    left:-20px;
    top: 100%;
    z-index: 999;
    -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 3px 5px #3333334d;
    transform-origin: 0 0 0;
    transform: scaleY(0.2);
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    opacity: 0;
    visibility: hidden;
    top: 74px;
    border-left:3px solid #2889e4;
    }
    .header .nav li:hover .dropdown{
    opacity:1;
    visibility:visible;
    transform:translateY(0px);
    }
    .header .nav li .dropdown li{
    float:none;
    margin:0;
    border-bottom:1px dashed #eee;
    }
    .header .nav li .dropdown li:last-child{
    border:none;
    }
    .header .nav li .dropdown li a {
    padding: 12px 15px;
    color: #666;
    display: block;
    font-weight: 400;
    text-transform: capitalize;
    background: transparent;
    -webkit-transition:all 0.2s ease;
    -moz-transition:all 0.2s ease;
    transition:all 0.2s ease;
    }
    .header .nav li .dropdown li a:before{
    display:none;
    }
    .header .nav li .dropdown li:last-child a{
    border-bottom:0px;
    }
    .header .nav li .dropdown li:hover a{
    color:#1A76D1;
    }
    .header .nav li .dropdown li a:hover{
    border-color:transparent;
    }
    /* Right Bar */
    .header.style2 .main-menu{
    display:inline-block;
    float:left;
    }
    .header .right-bar {
    float: right;
    }
    .header .right-bar {
    padding-top:20px;
    }
    .header .right-bar {
    display: inline-block;
    }
    .header .right-bar a {
    color: #fff;
    height: 35px;
    width: 35px;
    line-height: 35px;
    text-align: center;
    background: #1a76d1;
    border-radius: 4px;
    display: block;
    font-size: 12px;
    }
    .header .right-bar li a:hover{
    color:#fff;
    background:#27AE60;
    }
    .header .search-top.active .search i:before{
    content:"\eee1";
    font-size:15px;
    }
    /* Search */
    .header .search-form {
    position: absolute;
    right: 0;
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    top: 74px;
    box-shadow: 0px 0px 10px #0000001c;
    border-radius: 4px;
    overflow: hidden;
    transform:scale(0);
    }
    .header .search-top.active .search-form {
    opacity:1;
    visibility:visible;
    transform:scale(1);
    }
    .header .search-form input {
    width: 282px;
    height: 50px;
    line-height: 50px;
    padding: 0 70px 0 20px;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    border-radius: 3px;
    border: none;
    background: #fff;
    color: #2C2D3F;
    }
    .header .search-form button {
    position: absolute;
    right: 0;
    height: 50px;
    top: 0;
    width: 50px;
    background: #1A76D1;
    border: none;
    color: #fff;
    border-radius: 0 4px 4px 0;
    border-left:1px solid transparent;
    }
    .header .search-form button:hover{
    background:#fff;
    color:#1A76D1;
    border-color:#e6e6e6;
    }
    /* Header Sticky */
    .header.sticky .header-inner{
    position:fixed;
    z-index:999;
    top:0;
    left:0;
    bottom:initial;
    -webkit-transition:all 0.4s ease;
    -moz-transition:all 0.4s ease;
    transition:all 0.4s ease;
    animation: fadeInDown 0.5s both 0.1s;
    box-shadow:0px 0px 13px #00000054;
    }
    /*=========================
    End Header CSS
    ===========================*/