/*=========================
	Srart service CSS
===========================*/
.services .single-service{
	margin:30px 0;
	position:relative;
	padding-left:70px;
}
.services .single-service i{
	font-size:45px;
	color:#1a76d1;
	position:absolute;
	left:0;
	-webkit-transition:all 0.4s ease;
	-moz-transition:all 0.4s ease;
	transition:all 0.4s ease;
}
.services .single-service h4{
	text-transform:capitalize;
	margin-bottom:25px;
	color:#2C2D3F;
}
.services .single-service h4 a{
	color:#2C2D3F;
	font-size:20px;
	font-weight:600;
}
.services .single-service h4 a:hover{
	color:#1A76D1;
}
.services .single-service p{
	color:#868686;
}
/*-- Service Details --*/
.services-details-img {
  margin-bottom: 50px;
}

.services-details-img img {
  width: 100%;
  margin-bottom: 30px;
}
.services-details-img h2 {
  font-weight: 600;
  font-size: 28px;
  margin-bottom: 16px;
}
.services-details-img P {
  margin-bottom: 20px;
}
.services-details-img blockquote {
	font-size: 15px;
	color: #4a6f8a;
	background-color: #1a76d1;
	padding: 30px 75px;
	line-height: 26px;
	position: relative;
	margin-bottom: 20px;
	color: #fff;
}
.services-details-img blockquote i {
  position: absolute;
  display: inline-block;
  top: 20px;
  left: 38px;
  font-size: 32px;
}
.service-details-inner-left {
  /* background-image: url("img/signup-bg.jpg"); */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.service-details-inner-left img {
  display: none;
}
.service-details-inner {
  max-width: 580px;
  margin-left: auto;
  margin-right: auto;
}
.service-details-inner h2 {
	font-weight: 600;
	font-size: 30px;
	margin-bottom: 15px;
	border-left: 3px solid #1a76d1;
	padding-left: 15px;
}
.service-details-inner p {
  margin-bottom: 15px;
}
.service-details-inner p:last-child {
  margin-bottom: 0;
}
/*=========================
	End service CSS
===========================*/
